import React, { useState, useEffect, useRef } from "react";
import Button from "../Button/Button";
import PortfolioSection from "./PortfolioSection/PortfolioSection";
import CTASection from "./CTASection/CTASection";
import { useNavigate } from "react-router-dom";

function WebsiteService() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navigate = useNavigate()

  const handleScroll = () => {
    const scrolled = window.scrollY > 0;
    setIsScrolled(scrolled);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const portfolioRef = useRef(null);

  const ctaSectionRef = useRef(null);

  const handlePortfolioClick = () => {
    if (portfolioRef.current) {
      portfolioRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const checkAndScroll = () => {
      // Scroll to the top on component mount
      window.scrollTo({ top: 0, behavior: 'auto' });
    };

    // Check and scroll on component mount
    checkAndScroll();

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    return () => {
      // Remove scroll event listener on component unmount
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <div className="text-white">
      <nav
        className={`w-full fixed top-0 bg-black shadow-lg transition-all duration-300 ${isScrolled ? "py-6" : "py-6"
          }`}
        style={{ zIndex: 1000 }}
      >
        <div className="mx-4 md:mx-20">
          <div className="flex justify-between items-center">
            <div className="text-xl font-bold text-white">
              <button onClick={() => navigate('/')}>HOME</button>
            </div>
            <div className="md:hidden">
              <button
                className="text-white focus:outline-none"
                onClick={toggleMenu}
              >
                {!isMenuOpen ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16m-7 6h7"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
            <div className="hidden md:flex space-x-20 text-xl font-bold">
              <a
                href="#"
                className="text-white"
                onClick={handlePortfolioClick}
              >
                Portfolio
              </a>
            </div>
          </div>
          {isMenuOpen && (
            <div className="md:hidden mt-4 space-y-4">
              <a
                href="#"
                className="block text-white"
                onClick={handlePortfolioClick}
              >
                Portfolio
              </a>
            </div>
          )}
        </div>
      </nav>

      <div className="mx-4 lg:mx-20 mt-52 mb-40">
        <div className="ml-1">
          <p className="text-xl md:text-3xl" style={{ color: "#00D062", letterSpacing: "0.25em", fontWeight: "bold" }}>
            SHAPE YOUR DIGITAL PRESENCE
          </p>
        </div>
        <div className="mt-2">
          <p className="text-2xl md:text-5xl font-semibold inline">
            Build Your Website To Connect <br className="hidden md:block" /> With Your Audience
          </p>
          <p className="text-2xl md:text-5xl inline">
            {" "}
            And Construct <br className="hidden md:block" /> A Journey To A{" "}
            <span className="underline"> Future Defined By <br className="hidden md:block" /> Growth And Freedom.</span>
          </p>
        </div>
        <div className="mt-4 md:mt-10 w-full md:w-1/3">
          <Button message="I'm Ready" onClickFunction={() => ctaSectionRef.current.scrollIntoView({ behavior: 'smooth' })} wfull={true} />
        </div>
      </div>

      <div id="portfolio" ref={portfolioRef}>
        <PortfolioSection />
      </div>

      <div ref={ctaSectionRef}>
        <CTASection />
      </div>
    </div>
  );
}

export default WebsiteService;