import projects from './projects'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import CTA from '../CTASection/CTA';

function ShowcasePage() { 

    const navigate = useNavigate()

    const { projectName } = useParams();
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
      };

    
    useEffect(() => {
        console.log(projectName)
        //console.log(projects[projectName].type)
    }, [projectName])

    const handleScroll = () => {
        const scrolled = window.scrollY > 0;
        setIsScrolled(scrolled);
    };

    useEffect(() => {
        const checkAndScroll = () => {
          // Scroll to the top on component mount
          window.scrollTo({ top: 0, behavior: 'auto' });
        };
    
        // Check and scroll on component mount
        checkAndScroll();
    
        // Add scroll event listener
        window.addEventListener("scroll", handleScroll);
    
        return () => {
          // Remove scroll event listener on component unmount
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);

    return (
        // onClickFunction={() => 
        //     navigate('/webdevelopment/ap')}/>

        <div>

<nav
        className={`w-full fixed top-0 bg-black shadow-lg transition-all duration-300 ${
          isScrolled ? "py-6" : "py-6"
        }`}
        style={{ zIndex: 1000 }}
      >
        <div className="mx-4 md:mx-20">
          <div className="flex justify-between items-center">
            <div className="text-xl font-bold text-white">
                <button onClick={() => navigate('/')}>HOME</button> 
            </div>
            <div className="md:hidden">
              <button
                className="text-white focus:outline-none"
                onClick={toggleMenu}
              >
                {!isMenuOpen ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16m-7 6h7"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
      </nav>

            <div className="bg-white text-black flex flex-col items-center h-full">
                <div className='flex flex-col items-center mt-32 mb-16 md:mb-32 mx-8 lg:mx-20'>
                    <div className='flex flex-col items-center'>
                        <img src={projects[projectName].title} className='md:scale-125'/>
                        <h6 className="text-black mt-4 text-2xl md:text-h6">{projects[projectName].type}</h6>
                    </div>
                    <img src={projects[projectName].image} className='mt-10'/>
                    <p className='mt-10 text-xl md:text-4xl text-center'>{projects[projectName].descr}</p>
                </div>
            </div>
            <CTA />
            <div className="mx-8 lg:mx-20">
                <hr className="mt-24" />
                <p className="mt-12 text-center font-bold mb-12">© All Rights Reserved</p>
            </div>
        </div>


    )

}

export default ShowcasePage;