import { useState, useEffect, useRef } from "react";
import Button from "../Button/Button";
import emailjs from "@emailjs/browser";

function CTA() {

    const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    useEffect(() => emailjs.init("bxeiJse7eRJV-4PmM"), []);
    const [validEmail, setValidEmail] = useState(true);
    const [emailSent, setEmailSent] = useState(false);

    const emailRef = useRef();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const localValid = emailPattern.test(emailRef.current.value);

        if (!localValid) {
            setValidEmail(false);
            await sleep(2000);
            setValidEmail(true);
            return;
        } else {
            const serviceId = "service_h8jh4r8";
            const templateId = "template_pj925oe";
            try {
                setLoading(true);
                setEmailSent(true);
                await emailjs.send(serviceId, templateId, {
                    client_email: emailRef.current.value
                });
                //alert("email successfully sent check inbox");
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
                await sleep(2000);
                setEmailSent(false);
            }
        }

    }

    return (

        <div className="mx-4 md:mx-20">
            <div className="flex flex-col items-center">
                <p className="text-sm md:text-3xl text-center font-light mt-4">Enter Your Email And I Will Get Back To <br />
                    You To Book An Appointment</p>
                <div className="w-5/6 md:w-1/3 mt-2 mb-4 text-black text-center">
                    <input type="text" id="email" ref={emailRef} required className={` ${validEmail ? '' : 'bg-red-500'} w-full h-16 p-4`} placeholder="Enter Your Email"></input>
                    <p className={`text-base md:text-3xl text-center font-light mt-4 
                    transition-opacity duration-500 ease-in-out text-white ${emailSent ? 'opacity-100' : 'opacity-0 hidden'} `}>Email Sent!</p>
                </div>
                <div>
                    <Button message="Submit" onClickFunction={handleSubmit} />
                </div>
                {/* <Button message="Submit" onClickFunction={handleSubmit}/> */}
            </div>
        </div>

    )

};

export default CTA;