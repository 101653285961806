import Button from "../Button/Button"
import island from "../../images/island.svg"
import mountain from "../../images/mountain.svg"
import rocket from "../../images/rocket.svg"
import styles from './Resources.module.css'
import React from "react"
import { useNavigate } from 'react-router-dom';

const Resources = React.forwardRef((_, ref) => {

    const navigate = useNavigate();

    return (

        <div className="bg-white text-black" ref={ref}>
            <div className="mx-2 lg:mx-20 flex flex-col items-center">
                <h6 className="text-black text-tinyh6 md:text-6xl mt-10 md:mt-20">RESOURCES</h6>
                <div className="grid grid-cols-1 xl:grid-cols-3 gap-12 xl:gap-4 mt-3 sm:mt-12">
                    <div className="col-span-1 flex">
                        <div className="flex flex-col md:space-y-4 flex-1 justify-between"> {/* space-y-4 applies same space btw all the elements */}
                            <img src={rocket} />
                            <div className="ml-2 mr-2 mt-2 md:mt-0">
                                <div className="mr-10 xl:ml-0 mb-2">
                                    <h2 className="text-tinyh2 sm:text-h2">Website Development</h2>
                                    <p className="text-ptiny sm:text-p">A strong digital presence is a must <br className="hidden md:block" />
                                        for every entrepreneur. Together <br className="hidden md:block" /> we’ll transform your website into a <br className="hidden md:block" />
                                        powerhouse that attracts, engages, <br className="hidden md:block" /> and converts.</p>
                                </div>

                                {/* <Button message="Learn More" /> */}
                                <Button message="Learn More" available={true} wfull={true}
                                    onClickFunction={() => navigate('/webdevelopment')} />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1 flex">
                        <div className="flex flex-col md:space-y-4 flex-1 justify-between">
                            <img src={mountain} />
                            <div className="ml-2 mr-2 mt-2 md:mt-0 flex flex-1 flex-col justify-between">
                                <div className="mr-10 xl:ml-0 mb-2">
                                    <h2 className="text-tinyh2 sm:text-h2">Coaching</h2>
                                    <p className="text-ptiny sm:text-p">I’ll help you uncover your core <br className="hidden md:block" />
                                        values and forge your own unique <br className="hidden md:block" />
                                        path towards freedom and a life <br className="hidden md:block" />
                                        that aligns with your aspirations and potential. </p>
                                </div>
                                <Button message="Learn More" available={true} wfull={true}
                                    onClickFunction={() => navigate('/coaching')} />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-1 flex">
                        <div className="flex flex-col md:space-y-4 flex-1 justify-between">
                            <img src={island} />
                            <div className="ml-2 mr-2 md:ml-1 md:mr-1 mt-2 md:mt-0">
                                <div className="mr-10 xl:ml-0 mb-2">
                                    <h2 className="text-tinyh2 sm:text-h2">Remote Job Mastery</h2>
                                    <p className="text-ptiny sm:text-p">Learn the best skills to land a <br className="hidden md:block" />
                                        remote job and break free from the <br className="hidden md:block" />
                                        9-to-5 grind. Boost your career, <br className="hidden md:block" />
                                        embrace flexibility, and redefine <br className="hidden md:block" />
                                        your work-life balance.</p>
                                </div>
                                <Button message="Learn More" wfull={true} available={false} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

});

export default Resources;
