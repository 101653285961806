import chatapp_title from '../../../images/chatapp_title.svg'
import chatapp_img from '../../../images/chatapp_img.svg'
import mp_img from '../../../images/mp_img.svg'
import mp_title from '../../../images/mp_title.svg'
import ap_img from '../../../images/ap_img.svg'
import ap_title from '../../../images/ap_title.svg'

const projects = {

    'chatapp': {
        'type' : 'LANDING PAGE',
        'title' : chatapp_title,
        'image' : chatapp_img,
        'descr' : 'Landing page design for a team chat application'
    },
    'mp': {
        'type' : 'PORTFOLIO',
        'title' : mp_title,
        'image' : mp_img,
        'descr' : 'Portfolio design for a freelancer'
    },
    'ap': {
        'type' : 'PORTFOLIO',
        'title' : ap_title,
        'image' : ap_img,
        'descr' : 'Portfolio design for a freelancer'
    },

}

export default projects;