import Button from "../Button/Button"
import { useState, useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";

export default function Footer() {

    const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    useEffect(() => emailjs.init("bxeiJse7eRJV-4PmM"), []);
    const [validEmail, setValidEmail] = useState(true);
    const [emailSent, setEmailSent] = useState(false);

    const emailRef = useRef();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const localValid = emailPattern.test(emailRef.current.value);

        if (!localValid) {
            setValidEmail(false);
            await sleep(2000);
            setValidEmail(true);
            return;
        } else {
            const serviceId = "service_h8jh4r8";
            const templateId = "template_6wzh1jr";
            try {
                setLoading(true);
                setEmailSent(true);
                await emailjs.send(serviceId, templateId, {
                    client_email: emailRef.current.value
                });
                //alert("email successfully sent check inbox");
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
                await sleep(2000);
                setEmailSent(false);
            }
        }

    }

    return (

        <div>
            <div className="mx-8 lg:mx-20 mt-10 md:mt-20 flex flex-col items-center sm:items-start">
                {/* <p className="font-bold text-5xl">Craft Your Future</p> */}
                <h6 className="text-tinyh6 md:text-5xl text-center md:text-start">CRAFT YOUR FUTURE</h6>
                <p className='text-aboutptiny sm:text-aboutp font-semibold mt-2 md:mt-6'>I create custom-tailored websites, write about self-improvement, <br className="hidden md:block" />
                    philosophy, and business to improve your life with a holistic approach.</p>
                <div className="mt-8 flex flex-col w-full">
                    <div className="mt-2 mb-4 md:w-2/5 text-black">
                        <input type="text" id="email" ref={emailRef} required className={` ${validEmail ? '' : 'bg-red-500'} w-full h-auto p-5`} placeholder="Your Best Email"></input>
                        <p className={`text-base md:text-3xl font-light mt-4 
                        transition-opacity duration-100 ease-in-out text-white ${emailSent ? 'opacity-100' : 'opacity-0 hidden'} `}>Subscribed!</p>
                    </div>
                    <div className="w-full md:w-2/5">
                        <Button message='Try it out (it&apos;s free)' onClickFunction={handleSubmit} wfull={true} />
                    </div>
                </div>
            </div>
            <div className="mx-8 lg:mx-20">
                <p className="mt-16 text-center font-bold mb-4">© All Rights Reserved</p>
            </div>
        </div>


    )


}





