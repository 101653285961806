import ta from '../../images/ta.jpg'
import meta_learner from '../../images/meta_learner.jpg'
import Button from '../Button/Button'
import { useNavigate } from 'react-router-dom';

export default function Newsletter() {

    const navigate = useNavigate();
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (

        <div className='bg-white text-black'>
            <div className='flex flex-col items-center mx-2 md:mx-20'>
                <h6 className="text-black text-tinyh6 md:text-6xl mt-20 md:mt-32">NEWSLETTER</h6>
                <div className='mb-8 w-full'>
                    <div className="-mt-4 md:mt-16">
                        <div className="flex flex-col-reverse lg:flex-row mb-16 mt-8 md:mt-0 mx-2 lg:mx-0">
                            <div className="flex-1 flex flex-col justify-between">
                                <div>
                                    <h2 className="text-tinyh2 md:text-3xl mt-6 lg:mt-0 md:mr-10">How To Learn Anything (And Become Irreplaceable)</h2>
                                    <p className="text-ptiny md:text-p md:mt-6 md:mr-16">
                                        In today's world, learning is essential for survival. Dive deep into topics that intrigue you,
                                        focusing on solving your own problems. Take charge, embrace challenges, and thrive in the modern era.
                                    </p>
                                </div>

                                <div className="mt-4 md:mt-10 md:w-2/3">
                                    <Button message="Read More" onClickFunction={() => { navigate('/article2') }} wfull={true} />
                                </div>
                            </div>
                            <div className="flex-1">
                                <img src={meta_learner} className="w-full drop-shadow-xl" />
                            </div>
                        </div>
                        <div className="flex flex-col-reverse lg:flex-row mb-16 mt-8 md:mt-0 lg:space-y-0 mx-2 lg:mx-0">
                            <div className="flex-1 flex flex-col justify-between">
                                <div>
                                    <h2 className="md:text-3xl mt-6 lg:mt-0 md:mr-10">How To Get Your Life Back (Stop Overthinking)</h2>
                                    <p className="text-ptiny md:text-p md:mt-6 md:mr-16">
                                        Escape the overthinking trap fueled by fear and societal pressure by focusing on
                                        what's controllable, embracing failure as a learning opportunity, and adopting a
                                        proactive mindset to unlock your potential.
                                    </p>

                                </div>

                                <div className="mt-4 md:mt-10 md:w-2/3">
                                    <Button message="Read More" onClickFunction={() => { navigate('/article1') }} wfull={true} />
                                </div>
                            </div>
                            <div className="flex-1">
                                <img src={ta} className="w-full drop-shadow-xl" />
                            </div>
                        </div>
                    </div>
                    {/* <NewsElement />
                    <NewsElement /> */}
                </div>
            </div>
        </div>

    )


}