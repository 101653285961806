import chatapp from '../../../images/chatapp.svg'
import mp from '../../..//images/mp.svg'
import ap from '../../..//images/ap.svg'
import Button from '../../Button/Button'
import ShowcasePage from '../ShowcasePage/ShowcasePage'
import { useNavigate } from 'react-router-dom';


function PortfolioSection() {

    const navigate = useNavigate();

    return (

        <div className="bg-white">
            <div className="mx-2 lg:mx-16 flex flex-col items-center">
                <h6 className="text-black text-tinyh6 md:text-h6 mt-20">PORTFOLIO</h6>
                <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mt-8 mb-32'>
                    <div className='flex flex-col items-center space-y-5'>
                        <div className='flex flex-col items-center'>
                            <img src={chatapp} />
                            <div className='w-full px-1 text-center mt-4'>
                                <Button message="See Project" available={true} wfull={true}
                                    onClickFunction={() =>
                                        navigate('/webdevelopment/chatapp')} />
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col items-center space-y-5'>
                        <div className='flex flex-col items-center'>
                            <img src={mp} />
                            <div className='w-full px-1 text-center mt-4'>
                                <Button message="See Project" available={true} wfull={true}
                                    onClickFunction={() =>
                                        navigate('/webdevelopment/mp')} />
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col items-center space-y-5'>
                        <div className='flex flex-col items-center'>
                            <img src={ap} />
                            <div className='w-full px-1 text-center mt-4'>
                                <Button message="See Project" available={true} wfull={true}
                                    onClickFunction={() =>
                                        navigate('/webdevelopment/ap')} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}

export default PortfolioSection;