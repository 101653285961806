import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

const NavBar = ({ handleClickResources, handleClickAbout }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navigate = useNavigate()

  const handleScroll = () => {
    const scrolled = window.scrollY > 0;
    setIsScrolled(scrolled);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav
      className={`w-full fixed top-0 bg-black shadow-lg transition-all duration-300 ${isScrolled ? 'py-6' : 'py-6'
        }`}
      style={{ zIndex: 1000 }}
    >
      <div className="mx-4 md:mx-20">
        <div className="flex justify-between items-center">
          <div className="text-xl font-bold text-white">ANDREA PERRI</div>
          <div className="md:hidden">
            <button
              className="text-white focus:outline-none"
              onClick={toggleMenu}
            >
              {!isMenuOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              )}
            </button>
          </div>
          <div className="hidden md:flex space-x-20 text-xl font-bold">
            <a
              href="#"
              className="text-white"
              onClick={handleClickResources}
            >
              Resources
            </a>
            <a href="#" className="block text-white" onClick={() => navigate('/coaching')}>
              Coaching
            </a>
            <a href="#" className="text-white" onClick={handleClickAbout}>
              About
            </a>
          </div>
        </div>
        {/* Responsive menu */}
        {isMenuOpen && (
          <div className="md:hidden mt-4 space-y-4">
            <a
              href="#"
              className="block text-white"
              onClick={handleClickResources}
            >
              Resources
            </a>
            <a href="#" className="block text-white" onClick={() => navigate('/coaching')}>
              Coaching
            </a>
            <a href="#" className="block text-white" onClick={handleClickAbout}>
              About
            </a>
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
