import NavBarCoaching from "../../CoachingService/NavBarCoaching/NavBarCoaching"
import island from "../../../images/island.svg"
import mountain from "../../../images/mountain.svg"
import rocket from "../../../images/rocket.svg"
import Article1 from "./Articles/article1/Article1"
import Article2 from "./Articles/article2/Article2"
import Button from "../../Button/Button"
import styles from './NewsletterPage.module.css'
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

export default function NewsletterPage({ article: Article }) {

    const [isScrolled, setIsScrolled] = useState(false);

    const navigator = useNavigate();

    const handleScroll = () => {
        const scrolled = window.scrollY > 0;
        setIsScrolled(scrolled);
    };

    useEffect(() => {
        const checkAndScroll = () => {
            // Scroll to the top on component mount
            window.scrollTo({ top: 0, behavior: 'auto' });
        };

        // Check and scroll on component mount
        checkAndScroll();

        // Add scroll event listener
        window.addEventListener("scroll", handleScroll);

        return () => {
            // Remove scroll event listener on component unmount
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (

        <div>
            <NavBarCoaching />
            <div className="mt-40 flex flex-col lg:flex-row mx-4 md:mx-20">
                <div className="bg-white flex-1">
                    <div className="mt-8 mb-6">
                        <Article />
                    </div>
                </div>
                <div className="flex flex-col flex-2 md:ml-4 mt-10 md:mt-0">
                    <div className="mb-24">
                        <div className="border-2 border-white w-full ">
                            <img src={rocket} />
                        </div>
                        <p className="font-bold">Website Development</p>
                        <p className="text-ptiny sm:text-p -mt-4">A strong digital presence is a must <br />
                            for every entrepreneur. Together <br /> we’ll transform your website into a <br />
                            powerhouse that attracts, engages, <br /> and converts.</p>
                        <Button message="Learn More" onClickFunction={() => navigator('/webdevelopment')} />
                    </div>
                    <div className="mb-24">
                        <div className="border-2 border-white">
                            <img src={mountain} />
                        </div>
                        <p className="font-bold">Coaching</p>
                        <p className="text-ptiny sm:text-p -mt-4">I’ll help you uncover your core <br />
                            values and forge your own unique <br />
                            path towards freedom and a life <br />
                            that aligns with your aspirations and <br /> potential. </p>
                        <Button message="Learn More" onClickFunction={() => navigator('/coaching')} />
                    </div>
                    <div className="mb-24">
                        <div className="border-2 border-white">
                            <img src={island} />
                        </div>
                        <p className="font-bold">Remote Job Mastery</p>
                        <p className="text-ptiny sm:text-p -mt-4">Learn the best skills to land a <br />
                            remote job and break free from the <br />
                            9-to-5 grind. Boost your career, <br />
                            embrace flexibility, and redefine <br />
                            your work-life balance.</p>
                        <Button message="Learn More" available={false} />
                    </div>
                </div>
            </div>
            <div className="mx-8 md:mx-20">
                <hr className="mt-4 md:mt-24" />
                <p className="mt-12 text-center font-bold mb-12">© All Rights Reserved</p>
            </div>
        </div>

    )


}