import React, { useState } from 'react';

function Button({ message, available, wfull, onClickFunction, px, pxSmall }) {

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const buttonStyle = {
        backgroundColor: isHovered ? '#FFFFFF' : '#00D062',
        color: isHovered ? '#00D062' : '#FFFFFF',
        border: isHovered ? '2px solid #000000' : '2px solid transparent',
        transition: 'background-color 0.3s',
        // Add other styles as needed
    };

    const notAvailableButtonStyle = {
        backgroundColor: isHovered ? '#FFFFFF' : '#00D062',
        color: isHovered ? '#C41E3A' : '#FFFFFF',
        border: isHovered ? '2px solid #C41E3A' : '2px solid transparent',
        transition: 'background-color 0.3s',
    }

    return (
        <div className='w-full'>
            <button
                style={available ? buttonStyle : notAvailableButtonStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={onClickFunction}
                className={`${wfull ? 'w-full' : ''} ${pxSmall} lg:px-32 text-white font-bold py-3 xl:py-5 drop-shadow-xl`}>
                <h5 className='text-base lg:text-xl'>{available ? message : 'Coming Soon'}</h5>
            </button>
        </div>

    )

}

Button.defaultProps = {
    available: true,
    wfull: false,
    px: 'px-32',
    pxSmall: 'px-24'
};

export default Button;