import NavBarCoaching from "./NavBarCoaching/NavBarCoaching"
import me_coaching from "../../images/me_coaching.svg"
import Button from "../Button/Button"
import CTA from "./CTA"
import styles from './CoachingService.module.css'
import React, { useRef, useEffect, useState } from 'react';

export default function CoachingService() {

    const myDivRef = useRef(null);
    const [isScrolled, setIsScrolled] = useState(false);

    const handleButtonClick = () => {
        // Scroll to the div when the button is clicked
        myDivRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const handleScroll = () => {
        const scrolled = window.scrollY > 0;
        setIsScrolled(scrolled);
    };

    useEffect(() => {
        const checkAndScroll = () => {
            // Scroll to the top on component mount
            window.scrollTo({ top: 0, behavior: 'auto' });
        };

        // Check and scroll on component mount
        checkAndScroll();

        // Add scroll event listener
        window.addEventListener("scroll", handleScroll);

        return () => {
            // Remove scroll event listener on component unmount
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (

        <div>
            <NavBarCoaching />
            <div className="bg-white text-black mt-16">
                <div className="mx-2 lg:mx-20 flex flex-col items-center">
                    <h6 className="text-black text-tinyh6 md:text-h6 mt-20">COACHING</h6>
                    <div className="flex flex-col items-center md:flex-row md:justify-center md:items-stretch mt-14 mb-20 md:mb-32">
                        {/* <div className="md:w-1/2 flex justify-center"> */}
                        <div className="md:w-1/2 flex justify-center">
                            <img src={me_coaching} />
                        </div>
                        {/* <div className="md:w-1/2 flex flex-col mx-2 md:mx-0"> */}
                        <div className="md:w-1/2 flex flex-col mx-2 md:mx-0">
                            <p className="font-bold text-4xl md:text-4xl">45-Minutes Consultation Call</p>
                            <div className="mt-6 text-xl md:text-3xl">
                                <p>One-to-one coaching call to:</p>
                                <p className="mt-6">- Gain clarity on how to build your online presence</p>
                                <p className="mt-6">- Ask specific questions about business and self-improvement topics</p>
                            </div>
                            <div className="mt-4 md:mt-auto">
                                <Button message={"I'm Ready To Start"} onClickFunction={handleButtonClick} wfull={window.innerWidth < 768 ? true : false} pxSmall={'px-16'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="mt-12 bg-lime-950 py-4 text-center">
                    <p className="text-sm md:text-2xl" style={{ color: '#00D062', letterSpacing: '0.25em', fontWeight: 'bold' }}>FREEDOM COMES FROM CLARITY</p>
                    <p className="text-2xl md:text-6xl font-extrabold leading-tight -mt-4">Shape Your Vision And <br /> Craft Your Reality</p>
                    <p className="text-base md:text-3xl text-center font-light -mt-4">Unlock Your Growth</p>
                </div>
                <div className="bg-white text-black mx-8 sm:mx-40 xl:mx-96 py-2 mt-8 md:py-6 mb-8">
                    <div className="mx-4 font-light md:font-medium">
                        <p><span className="font-bold">Gaining clarity</span> is not easy, but it is a fundamental step in your journey to freedom.</p>
                        <p>If you don’t choose a purpose, you will be assigned one.</p>
                        <p>If you don’t set a goal, you will be assigned one.</p>
                        <p>If you don’t build a plan, you will be assigned one.</p>
                        <p>If you don’t choose a career, you will be assigned one.</p>
                        <p>If you don’t choose a life, you will be assigned one.</p>
                        <p>Shaping your vision is the first step towards achieving clarity on your path and a fulfilling life.</p>
                        <p>The average person <span className="font-bold">never questions anything.</span> </p>
                        <p>Go to school. Find a “secure” job. Work harder than anyone else to climb the status ladder (while neglecting all other aspects that make a good life).
                            Earn just enough money to survive. Retire at 65+ years old. Finally, enjoy the rest of your time.</p>
                        If this picture scares you to hell, good, you’re in the right place.
                        <p>When we fail to <span className="font-bold">take control of our lives</span> and make decisions for ourselves, society will.</p>
                        <p>Outsourcing your choices will inevitably lead to a life you don’t want.</p>
                        <p>Lack of clarity results in <span className="font-bold">procrastination, anxiety, and feeling overwhelmed.</span> In the worst-case scenario, you may wake up years from now wondering where all the time went.</p>
                        <p>The cure to a wasted potential is found in awareness coupled with consistent action.</p>
                        If you struggle to stay on track but don't know why it's because your actions are not aligned with your values yet.
                        <p>Everything starts with a <span className="font-bold">vision.</span></p>
                        <p><span className="font-bold">Let me tell you, this is not easy.</span> To figure out what you want, you first need to understand how you don’t want to end up.</p>
                        <p>You’ll have to dig deep into your life and past experiences to identify your core values.</p>
                        <p>From there you can start visualizing your ideal future, breaking it down into a hierarchy of goals that will keep you on track as you move on to a better life.</p>
                        <p>After years of studying, observing and experimenting with my own life, I decided to share this knowledge to help others gain clarity on their own path - <span className="italic">and a life of their choice.</span></p>
                    </div>
                </div>
                <div ref={myDivRef}>
                    <CTA />
                </div>
            </div>
            <div className="mx-8 lg:mx-20">
                <hr className="mt-24" />
                <p className="mt-12 text-center font-bold mb-12">© All Rights Reserved</p>
            </div>
        </div>

    )

}