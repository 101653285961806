import tw1 from './images/tweet.jpg';
import tw2 from './images/tweet2.jpg';


export default function Article2() {

    return (

        <div className="mx-10 text-black">
            <p>March 24, 2024 | Andrea Perri</p>
            <p className="font-black text-3xl md:text-5xl mt-2">How To Learn Anything (And Become Irreplaceable)</p>
            <div className="w-1/2 mt-6">
                <hr />
            </div>
            <div className='mt-6 text-md md:text-xl'>
                <p>Growing up I was curious about everything around me.</p>

                <p>I wanted to understand how things worked – and most importantly why they worked in a certain way.</p>

                <p>I had this innate curiosity that pushed me into wanting to learn more about the world around me.</p>

                <p>Curiosity is a very potent drive that can take you down an unimaginable path if you’re bold enough to pursue it.</p>

                <p>Everyone of us is born curious.</p>

                <p>In the early stages of our life we ask a lot of questions – that’s how our brain is wired for survival.</p>

                <p>But with time society tells us that there’s no more time for questions, you just have to execute what you’re told.</p>

                <p>Instead of getting rewarded, your curiosity is being punished.</p>

                <p>As a direct result your ability to learn new things suffers, making you unable to make progress in life.</p>

                <p>Learning a new skill becomes daunting and impossible to achieve.</p>

                <p>That’s because learning is difficult.</p>

                <p>It requires willpower, struggle and a lot of mistakes that you need to be willing to make in order to become aware of what you’re learning.</p>

                <p className="font-black text-3xl md:text-4xl">The School Trap</p>

                <p>The school system doesn’t foster curiosity, its role is to simply evaluate you on a given set of predetermined arguments that most of the time have nothing to do with your own inclinations.</p>

                <p>No wonder why you completely lose interest in learning something new.</p>

                <p>You’re forced to study subjects you have little to no interest in, which often are also pointless.</p>

                <p>Your mind adapts to the environment it is placed in – transforming you into a study-robot that is rewarded for not pursuing its own curiosity but simply following the predefined path laid out by society.</p>

                <p>Unfortunately, this curiosity stagnation is not something you can afford.</p>

                <p>In this rapidly evolving landscape the need for you to adapt and constantly acquire new skills is crucial.</p>

                <p>Your new approach to learning must be different from the traditional, structured curriculum and formal degrees that are no longer able to sustain the fast pace of this transforming economy.</p>

                <p>Learning doesn’t end after graduation as much as the world doesn’t stop evolving.</p>

                <p>The risk of losing your job is higher than ever – and it will only continue to grow, unless you take responsibility for your own education by fueling it with curiosity and a constant will to improve.</p>

                <p className="font-black text-4xl">Fail Fast To Learn Faster - Become A Problem Solver</p>

                <div className='my-4 mr-2'>
                    <img src={tw1} />
                </div>

                <p>Failure is not a side effect of learning, but a step of the process itself.</p>

                <p>You have to get used to the struggle of the early stages of learning – failing fast to learn faster.</p>

                <p>Everyone hates making mistakes.</p>

                <p>It makes you feel stupid, clueless, thinking you simply cannot do it.</p>

                <p>But if you manage to push through, learning to fall in love with the process of constantly trying things, failing and adapting, you’re up for an immense growth.</p>

                <p>Your success will be determined by your ability to learn from your mistakes FAST.</p>

                <p>Your priority must be building, trying, failing – and only then learning.</p>

                <p className="font-black text-4xl">How To Learn A New Skill</p>

                <p>If you want to thrive in this ever-changing environment you need to be well trained into the most important skills of all: learning.</p>

                <p>You’re not able to learn a new skill effectively because after a while you get overwhelmed by the amount of unnecessary information you fill your head with.</p>

                <p>So, instead of wanting to study every single detail of the topic you’re curious about, just start with the bare minimum that is required to make progress.</p>

                <p>The way to do this is by working on a personal project.</p>

                <p>Why? Because working on something yours is what keeps you motivated to keep going. Plus is very useful in order to be able to build a portfolio.</p>

                <p className="font-black text-2xl">Pick A Topic You Are Curious About</p>

                <p>Make a list of topics you’re interested in.</p>

                <p>These could be things you think may be useful, or simply arguments you’re curious about.</p>

                <p>Ask yourself: why do I want to learn this stuff?</p>

                <p>You have to be very specific here.</p>

                <p>Choose the topic that is most important to you right now.</p>

                <p className="font-black text-2xl">Research And Dive Deep</p>

                <p>To understand a topic you have to dive deep.</p>

                <p>Watch Youtube videos, read articles, tweets, posts, books, talk with people in the field.</p>

                <p>Surround yourself with content related to that argument.</p>

                <p className="font-black text-2xl">Pick A Project And Start Working</p>

                <div className='my-4 mr-2'>
                    <img src={tw2} />
                </div>

                <p>Now you have a high level idea of the topic.</p>

                <p>It’s time to make sense of all the information you’ve gained and start working on a project.</p>

                <p>This will reveal the gaps in your knowledge and it will serve you as a foundation for your future work.</p>

                <p>Don’t worry about finding the ideal project yet, start from some entry-level type of work.</p>

                <p>Taking action and working on something is much better than endlessly searching for the perfect project - which often just ends in a huge waste of time.</p>

                <p className="font-black text-2xl">Solve YOUR Problems</p>

                <p>This allows you to learn only the information that is actually useful for your goal – without having to waste time on topics you don’t even care about.</p>

                <p>Try it out.</p>

                <p>Suppose you want to learn how to code.</p>

                <p>Here’s an example:</p>

                <ul>
                    <li>Pick a language – could be HTML, JavaScript, C or else</li>
                    <li>Understand at a high level how the language works and what it is used for</li>
                    <li>Outline a project that you’re curious about</li>
                    <li>Work on the project</li>
                    <li>When you’re stuck search for a solution to fill your knowledge gaps</li>
                </ul>

                <p>Practice makes perfect.</p>

                <p>The more you practice the better you become at dissecting things in order to keep only the information relevant to solving YOUR problems.</p>

                <p>This is how you transform yourself into a problem-solving machine that is able to separate noise from the important stuff.</p>

                <p className="font-black text-4xl">Learn To Survive</p>

                <p>Learning a new skill is modern survival.</p>

                <p>If you want to reach your goals relying only on what you learned in school, you’re heading for trouble.</p>

                <p>The environment is constantly changing due to technological advancements.</p>

                <p>What used to take 10 people now takes 1.</p>

                <p>It’s your job to acquire the flexibility that allows you to become that single person who can adapt to the big challenges that lie ahead of our time - it’s your job to become a meta-learner.</p>

                <p>If you don’t know where to start, just pick a project and work on it.</p>

                <p>Fill your knowledge gaps with efficient trial and error, and watch your weaknesses become your strengths.</p>

                <p>Get to work, start building now and soon you’ll marvel at how far you’ve come.</p>

                <p>Thanks for reading.</p>

                <p>Andrea</p>

            </div>
        </div>

    )

}