

import Button from "../Button/Button"
import NavBar from "../NavBar/NavBar"
import Resources from "../Resources/Resources"
import Newsletter from "../Newsletter/Newsletter"
import AboutSection from "../AboutSection/AboutSection"
import Footer from "../Footer/Footer"
import React, { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import axios from 'axios';
import { Helmet } from 'react-helmet';



export default function Home() {


    const resourcesRef = useRef()
    const aboutRef = useRef()

    const [articles, setArticles] = useState([]);

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const response = await axios.get('http://infinitejourneyprova.wordpress.com/?rest_route=/wp-json/wp/v2/posts');
                setArticles(response.data);
            } catch (error) {
                console.error('Error fetching articles:', error);
            }
        };

        fetchArticles();
    }, []);

    const handleClickResources = () => {
        resourcesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    };

    const handleClickAbout = () => {
        aboutRef.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    };

    useEffect(() => {
        console.log(resourcesRef)
        console.log(aboutRef)
    }, [])

    const [isScrolled, setIsScrolled] = useState(false);

    const handleScroll = () => {
        const scrolled = window.scrollY > 0;
        setIsScrolled(scrolled);
    };

    useEffect(() => emailjs.init("bxeiJse7eRJV-4PmM"), []);
    const [validEmail, setValidEmail] = useState(true);
    const [emailSent, setEmailSent] = useState(false);

    const emailRef = useRef();
    const [loading, setLoading] = useState(false);

    const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const handleSubmit = async (e) => {
        e.preventDefault();

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const localValid = emailPattern.test(emailRef.current.value);

        if (!localValid) {
            setValidEmail(false);
            await sleep(2000);
            setValidEmail(true);
            return;
        } else {
            const serviceId = "service_h8jh4r8";
            const templateId = "template_6wzh1jr";
            try {
                setLoading(true);
                setEmailSent(true);
                await emailjs.send(serviceId, templateId, {
                    client_email: emailRef.current.value
                });
                //alert("email successfully sent check inbox");
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
                await sleep(2000);
                setEmailSent(false);
            }
        }

    }

    useEffect(() => {

        const checkAndScroll = () => {
            // Scroll to the top on component mount
            window.scrollTo({ top: 0, behavior: 'auto' });
        };

        // Check and scroll on component mount
        checkAndScroll();

        // Add scroll event listener
        window.addEventListener("scroll", handleScroll);

        return () => {
            // Remove scroll event listener on component unmount
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        // mx-4 md:mx-20

        <div className="text-white">
            <Helmet>
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="andreaperri.com" />
                <meta property="twitter:url" content="https://www.andreaperri.com" />
                <meta name="twitter:title" content="Andrea Perri - Website" />
                <meta name="twitter:description" content="Andrea Perri's Website" />
                <meta name="twitter:image" content="https://www.andreaperri.com/static/media/newsbanner.bef1ccad27fa65ff9a8f.png" />
            </Helmet>
            <NavBar handleClickResources={handleClickResources} handleClickAbout={handleClickAbout} />

            {articles.map(article => (
                <div key={article.id}>
                    <h2>{article.title.rendered}</h2>
                    <p dangerouslySetInnerHTML={{ __html: article.content.rendered }} />
                </div>
            ))}

            <div className="mt-32 md:mt-40 max-w-7xl ml-4 md:ml-20 mb-28 md:mb-40">
                {/* <h1 className="text-tinyh1 md:text-h1">Remote Mastery</h1> */}
                {/* <p className="text-xl md:text-3xl" style={{ letterSpacing: "0.25em", fontWeight: "bold" }}>
                    THE AUTHORITY CATALYST
                </p> */}
                <div className="articles-container">
                    {articles.map(article => (
                        <div key={article.id}>
                            <h2>{article.title.rendered}</h2>
                            <p dangerouslySetInnerHTML={{ __html: article.content.rendered }} />
                        </div>
                    ))}
                </div>
                <h1 className="text-2xl md:text-5xl font-bold">A <span style={{ color: "#00D062", fontWeight: "bold" }}>Web Designer </span>
                    That ALSO Knows <br className="hidden md:block" />
                    <span style={{ color: "#00D062", fontWeight: "bold" }}>Copywriting</span> and <span style={{ color: "#00D062", fontWeight: "bold" }}>Marketing</span>?!?
                </h1>
                <div className="mr-10 md:mr-0 mt-3">
                    <h4 className="text-tinyh4 md:text-h4.5 inline mr-2 md:mr-0">
                        Master the 3 highest leverage skills for building your <br className="hidden md:block" />
                        personal brand: copywriting, web design and <br className="hidden md:block" />
                        marketing. All in under 5 minutes a day.
                        {/* Learn about copywriting, web design, and marketing <br className="hidden md:block" />
                        in short emails (you’ll also get some life <br className="hidden md:block" /> advice you didn't ask for) */}
                    </h4>
                </div>
                {/* <div class="w-96 h-16 mt-4">
                    <iframe src="https://embeds.beehiiv.com/4f5f344e-3e5f-4dc0-8806-4ee7129d9df0?slim=true"
                        data-test-id="beehiiv-embed"
                        class="w-full h-full border-0"
                        style={{ margin: 0, borderRadius: '0px', backgroundColor: 'transparent' }}>
                    </iframe>
                </div> */}
                <div className="mt-8 md:mt-4 flex flex-col w-11/12 md:w-full">
                    <div className="md:w-1/2 flex flex-col text-black">
                        <input type="text" id="email" ref={emailRef} required className={` ${validEmail ? '' : 'bg-red-500'} w-full md:w-4/5 h-auto p-5`} placeholder="Your Best Email"></input>
                        <p className={`text-base md:text-3xl font-light mt-4 
                        transition-opacity duration-100 ease-in-out text-white ${emailSent ? 'opacity-100' : 'opacity-0 hidden'} `}>Subscribed!</p>
                        <div className="w-full md:w-4/5 mt-4">
                            <Button message='Try it out (it&apos;s free)' onClickFunction={handleSubmit} wfull={true} />
                        </div>
                    </div>
                </div>
                <div className="mt-2">
                    <p><span className="font-bold">PS. </span>
                        <span className="italic">
                            Don’t sign up if you’re not interested in levelling up your business and life.
                        </span>
                    </p>
                </div>

                {/* <div className="mt-6 flex justify-between">
                    <Button message="Discover More" onClickFunction={() => resourcesRef.current.scrollIntoView({ behavior: 'smooth' })} />
                </div> */}
            </div>
            <Resources ref={resourcesRef} />
            <Newsletter />
            <AboutSection ref={aboutRef} />
            <Footer />
        </div >

    )


}

// ------------------------------------------------------------------------------------------------------------------------
