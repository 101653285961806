import Button from "../../Button/Button";
import { useState, useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import CTA from "./CTA";
import styles from './CTASection.module.css';

function CTASection() {


    return (

        <div>
            <div className="mt-4">
                <div className="mt-12 bg-lime-950 text-center py-4 mb-6">
                    <p className="text-sm md:text-2xl" style={{ color: '#00D062', letterSpacing: '0.25em', fontWeight: 'bold' }}>REACH YOUR AUDIENCE</p>
                    <p className="text-3xl md:text-6xl font-extrabold leading-tight -mt-4">Why Does A Website <br /> Matter?</p>
                    <p className="text-base md:text-3xl text-center font-light -mt-4">Expand Your Horizon</p>
                </div>
            </div>
            <div className="bg-white text-black mx-8 sm:mx-40 xl:mx-96 py-2 md:py-6">
                <div className="mx-4 font-light md:font-medium">
                    <p className="font-extrabold">Visibility is the catalyst of selling.</p>
                    <p>There’s nothing more frustrating than having a good product and not being able to put it in front of the right people - your ideal customer.</p>
                    <p>A product is as good as people perceive it to be.</p>
                    <p>You can’t expect to help your customers if they don’t understand the potential value you can provide them.</p>
                    <p className="font-extrabold">Customization is the foundation of a service.</p>
                    <p>A product must be visible and presented in a way that appeals to the right people.</p>
                    <p>Your website must reflect yourself in order to attract an audience that resonates with you - customers who identify with your ideas and can benefit from your service.</p>
                    <p>That’s where a <span className="font-extrabold">custom-tailored website</span> comes into play.</p>
                    <p>I’m talking about a completely personalized virtual space where an entrepreneur can showcase not only its service, but also who they are as an individual.</p>
                    <p className="font-bold">Show yourself - attract likeminded people.</p>
                </div>
            </div>
            <CTA />
            <div className="mx-8 md:mx-20">
                <hr className="mt-24" />
                <p className="mt-12 text-center font-bold mb-12">© All Rights Reserved</p>
            </div>
        </div>

    )


}

export default CTASection;

