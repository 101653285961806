import aboutme from '../../images/aboutme.png'
import styles from './AboutSection.module.css'

import React from 'react';

const AboutSection = React.forwardRef((_, ref) => {

    return (
        <div ref={ref}>
            <div className="flex flex-col-reverse xl:flex-row items-center xl:items-center mt-20 mx-8 lg:mx-20">
                <div>
                    <img className='mt-8 xl:mt-0' src={aboutme} />
                </div>
                <div className='xl:ml-20'>
                    <h6 className='text-aboutmetiny sm:text-aboutme text-center md:text-start'>ABOUT ME</h6>
                    <p className='text-aboutptiny md:text-aboutp font-semibold mt-4 md:mt-8'>My name is Andrea Perri. I’m a coder passionate <br className='hidden md:block' />
                        about self-improvement and the idea of living my <br className='hidden md:block' /> life on my own terms.</p>
                    <p className='text-aboutptiny md:text-aboutp font-semibold mt-2 md:mt-8'>
                        I help people build their online presence and <br className='hidden md:block' />
                        learn the best way to achieve their personal <br className='hidden md:block' />
                        freedom.
                    </p>
                </div>
            </div>
            <div className='mx-8 lg:mx-20 mt-20'>
                <hr />
            </div>
        </div>
    )

});

export default AboutSection;