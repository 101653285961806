import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
//import Project from './components/Project/Project'
import Home from './Components/Home/Home';
import './styles.css'
import WebsiteService from './Components/WebsiteService/WebsiteService';
import ShowcasePage from './Components/WebsiteService/ShowcasePage/ShowcasePage';
import CoachingService from './Components/CoachingService/CoachingService';
import NewsletterPage from './Components/Newsletter/NewsletterPage/NewsletterPage';
import Article1 from './Components/Newsletter/NewsletterPage/Articles/article1/Article1';
import Article2 from './Components/Newsletter/NewsletterPage/Articles/article2/Article2';
import NewsletterBanner from './Components/NewsletterBanner/NewsletterBanner';

function App() {

  return (

    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/webdevelopment" element={<WebsiteService />} />
        <Route path="/webdevelopment/:projectName" element={<ShowcasePage />} />
        <Route path="/coaching" element={<CoachingService />} />
        <Route path="/article1" element={<NewsletterPage article={Article1} />} />
        <Route path="/article2" element={<NewsletterPage article={Article2} />} />
        <Route path="/newsletterbanner" element={<NewsletterBanner />} />
      </Routes>
    </Router>
  );
}

export default App;